import React from 'react';
import ContactForm from './include/ContactForm';

function Contact() {
  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
            className="breadcrumb-area bg-default  has-breadcrumb-overlay pt-130 pb-130"
            data-background="http://affiliate.universitybureau.com/content/uploads/2024/06/breadcrumb-img-1.webp"
        >
            <div className="container kd-container-1">
            <div className="row">
                <div className="col-xxl-12">
                <div className="breadcrumb-wrap">
                    <h2 className="breadcrumb-title kd-heading-1 kd-font-900 kd-h2-title-ani kd-h2-split-text">
                    Contact Us
                    </h2>
                </div>
                </div>
            </div>
            </div>
        </div>
        <div
            data-elementor-type="wp-page"
            data-elementor-id={1924}
            className="elementor elementor-1924"
        >
            <section
            className="elementor-section elementor-top-section elementor-element elementor-element-c124d77 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="c124d77"
            data-element_type="section"
            >
            <div className="elementor-container elementor-column-gap-default">
                <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-d452a87"
                data-id="d452a87"
                data-element_type="column"
                >
                <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                    className="elementor-element elementor-element-00ab0a1 elementor-widget elementor-widget-image"
                    data-id="00ab0a1"
                    data-element_type="widget"
                    data-widget_type="image.default"
                    >
                    <div className="elementor-widget-container">
                        <style
                        dangerouslySetInnerHTML={{
                            __html:
                            '\n                                  /*! elementor - v3.22.0 - 17-06-2024 */\n                                  .elementor-widget-image {\n                                      text-align: center\n                                  }\n  \n                                  .elementor-widget-image a {\n                                      display: inline-block\n                                  }\n  \n                                  .elementor-widget-image a img[src$=".svg"] {\n                                      width: 48px\n                                  }\n  \n                                  .elementor-widget-image img {\n                                      vertical-align: middle;\n                                      display: inline-block\n                                  }\n                              '
                        }}
                        />{" "}
                        <img
                        fetchpriority="high"
                        decoding="async"
                        width={611}
                        height={642}
                        src="http://affiliate.universitybureau.com/content/uploads/2024/06/contact-img-1.webp"
                        className="attachment-full size-full wp-image-1946"
                        alt=""
                        srcSet="http://affiliate.universitybureau.com/content/uploads/2024/06/contact-img-1.webp 611w, http://affiliate.universitybureau.com/content/uploads/2024/06/contact-img-1-600x630.webp 600w, http://affiliate.universitybureau.com/content/uploads/2024/06/contact-img-1-286x300.webp 286w"
                        sizes="(max-width: 611px) 100vw, 611px"
                        />
                    </div>
                    </div>
                </div>
                </div>
                <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8234f18"
                data-id="8234f18"
                data-element_type="column"
                >
                <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                    className="elementor-element elementor-element-45679de elementor-widget elementor-widget-go-s-heading"
                    data-id="45679de"
                    data-element_type="widget"
                    data-widget_type="go-s-heading.default"
                    >
                    <div className="elementor-widget-container">
                        <div className="prthalign">
                        <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                            contact us{" "}
                        </h6>
                        <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                            Get In Touch
                        </h1>
                        </div>
                    </div>
                    </div>
                    <div
                    className="elementor-element elementor-element-1996b2f elementor-widget elementor-widget-shortcode"
                    data-id="1996b2f"
                    data-element_type="widget"
                    data-widget_type="shortcode.default"
                    >
                    <div className="elementor-widget-container">
                        <div className="elementor-shortcode">
                        <div
                            className="wpcf7 js"
                            id="wpcf7-f1935-p1924-o1"
                            lang="en-US"
                            dir="ltr"
                        >
                            <div className="screen-reader-response">
                            <p role="status" aria-live="polite" aria-atomic="true" />
                            <ul />
                            </div>
                        </div>
                        </div>
                    </div>
                    <ContactForm></ContactForm>
                    </div>
                </div>
                </div>
            </div>
            </section>
            <section
            className="elementor-section elementor-top-section elementor-element elementor-element-75b3638 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="75b3638"
            data-element_type="section"
            >
            <div className="elementor-container elementor-column-gap-no">
                <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e7a9225"
                data-id="e7a9225"
                data-element_type="column"
                >
                <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                    className="elementor-element elementor-element-37c83f7 elementor-widget elementor-widget-google_maps"
                    data-id="37c83f7"
                    data-element_type="widget"
                    data-widget_type="google_maps.default"
                    >
                    <div className="elementor-widget-container">
                        <style
                        dangerouslySetInnerHTML={{
                            __html:
                            "\n                                  /*! elementor - v3.22.0 - 17-06-2024 */\n                                  .elementor-widget-google_maps .elementor-widget-container {\n                                      overflow: hidden\n                                  }\n  \n                                  .elementor-widget-google_maps .elementor-custom-embed {\n                                      line-height: 0\n                                  }\n  \n                                  .elementor-widget-google_maps iframe {\n                                      height: 300px\n                                  }\n                              "
                        }}
                        />
                        <div className="elementor-custom-embed">
                        <iframe
                            loading="lazy"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.0307929754376!2d77.03689807428431!3d28.418327693778018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d23883242f5ad%3A0xfcff2199d25c6257!2sIris%20Tech%20Park!5e0!3m2!1sen!2sin!4v1723375988494!5m2!1sen!2sin&t=m&z=10&output=embed&iwloc=near"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
        </div>


    </div>
  );
}


export default Contact;
