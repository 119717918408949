import React from "react";
import BG from "../../assets/imgs/banner-uk.png";

function UK() {
  const bg = BG;

  return (
    <div>
      <div id="page" className="site site_wrapper">
        <div
          className="breadcrumb-area bg-default  pt-130 pb-130"
          style={{ marginTop: 166 }}
          data-background={bg}
        >
          <div className="container kd-container-1">
            <div className="row">
              <div className="col-xxl-12">
                <div className="breadcrumb-wrap"></div>
              </div>
            </div>
          </div>
        </div>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Study In United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-495e7eb elementor-widget elementor-widget-go-pricing-paln"
                  data-id="495e7eb"
                  data-element_type="widget"
                  data-widget_type="go-pricing-paln.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      className="kd-price-4-content-wrap d-block"
                      style={{ paddingBottom: 70 }}
                    >
                      <div className="kd-price-4-content-bg img-cover fix txaa-scalex-down">
                        <img
                          decoding="async"
                          src="http://affiliate.universitybureau.com/content/uploads/2024/06/p3-item-bg-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-price-4-content">
                        <h5
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          Overview
                        </h5>
                        <p
                          className="disc kd-para-1 has-clr-white wow "
                          data-splitting=""
                        >
                          Attracting around 500,000 international students,
                          United Kingdom has emerged as a leading nation for
                          students looking for top-notch educational
                          opportunities. Study abroad in United Kingdom is
                          renowned for offering affordable, high-quality
                          education, and increases its allure with
                          internationally recognized degrees. But studying in
                          United Kingdom has advantages outside of the classroom
                          as well, which are:
                        </p>
                      </div>
                      <div className="kd-price-4-content-list">
                        <h4
                          className="title kd-heading-1 kd-font-900 has-clr-white wow"
                          data-splitting=""
                        >
                          benefits of United Kingdom
                        </h4>
                        <ul className="kd-list-1 kd-heading-1 kd-font-800">
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              High-Quality Education
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Affordable Tuition Fees
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Globally Recognized Degrees
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Quality of Life
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Post-Study Work Opportunities
                            </span>
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />
                            <span className="wow" data-splitting="">
                              Immigration Pathways
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e59fe91 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e59fe91"
          data-element_type="section"
          data-settings='{"background_background":"classic"}'
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-106a241"
              data-id="106a241"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-06bbe7e elementor-widget elementor-widget-go-s-heading"
                  data-id="06bbe7e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign" style={{ margin: "2rem 0" }}>
                      <h1 className="elementor-gt-heading text-center kd-section-title-1 kd-title-ani kd-split-text">
                        Why Choose United Kingdom for Your <br />
                        Academic Pursuits
                      </h1>
                      <p>
                        Study in United Kingdom is an excellent choice for
                        pursuing your academic goals, offering diverse
                        high-quality institutions and programs. Here are some
                        compelling reasons to consider United Kingdom for your
                        studies:
                      </p>
                    </div>
                  </div>
                  <div className="elementor-widget-container">
                    <div
                      id="categories-2"
                      className="widget_categories sidebar-widget"
                    >
                      <ul>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Academic Excellence:</b>
                              <br />
                              Uk's universities and colleges maintain academic
                              standards, and many institutions consistently rank
                              among the top globally.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Diverse Programs:</b>
                              <br />
                              United Kingdom provides various programs across
                              various disciplines, ensuring you can find a
                              course that aligns with your academic and career
                              aspirations.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Global Recognition:</b>
                              <br />
                              Degrees earned in United Kingdom are
                              internationally recognized and respected. A Uk's
                              education can open doors to global opportunities
                              and enhance your employability.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Cultural Diversity:</b>
                              <br />
                              United Kingdom is known for its multicultural
                              environment. Studying in United Kingdom allows you
                              to experience diverse cultures and perspectives,
                              fostering a rich and inclusive learning
                              environment.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Quality of Life:</b>
                              <br />
                              United Kingdom is renowned for its high quality of
                              life, safety, and welcoming communities. The
                              country consistently ranks among the best places
                              to live globally.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Innovation and Research Opportunities:</b>
                              <br />
                              Uk's institutions are at the forefront of research
                              and innovation. Students often have access to
                              state-of-the-art facilities and opportunities to
                              engage in cutting-edge research.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Language Options:</b>
                              <br />
                              United Kingdom is a bilingual country, offering
                              opportunities to study in English or French. This
                              language diversity can be an asset in the global
                              job market.
                            </div>
                          </a>
                        </li>
                        <li className="cat-item cat-item-25">
                          <a href="javascript:void(0)">
                            <div style={{ padding: 10 }}>
                              <b>Work Opportunities:</b>
                              <br />
                              United Kingdom provides various work opportunities
                              for international students, including part-time
                              work during studies and post-graduation work
                              permits, allowing you to gain valuable work
                              experience.
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-3163e91 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
          data-id="3163e91"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(http://affiliate.universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
          }}
        >
          <div className="elementor-container elementor-column-gap-no">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-888d83f"
              data-id="888d83f"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-789f478 elementor-widget elementor-widget-go-pricing-tab"
                  data-id="789f478"
                  data-element_type="widget"
                  data-widget_type="go-pricing-tab.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-price-1-area">
                      <div className="container kd-conainer-1">
                        <div className="kd-price-1-row d-flex">
                          {/* left-side */}
                          <div
                            className="kd-price-1-left w-100"
                            style={{
                              padding: 50,
                              justifyContent: "space-between",
                              gap: 200,
                            }}
                          >
                            {/* tabs-btn */}
                            <ul
                              className="kd-price-1-tabs-btn"
                              id="myTab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link active kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab0"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home0"
                                  type="button"
                                  role="tab"
                                  aria-controls="home0"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="http://affiliate.universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Undergraduate{" "}
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab1"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home1"
                                  type="button"
                                  role="tab"
                                  aria-controls="home1"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="http://affiliate.universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Post Graduate
                                </button>
                              </li>
                              <li className="nav-item" role="presentation">
                                <button
                                  className="nav-link  kd-heading-1 has-clr-white kd-font-800 font-c-40"
                                  id="home-tab2"
                                  data-bs-toggle="tab"
                                  data-bs-target="#home2"
                                  type="button"
                                  role="tab"
                                  aria-controls="home2"
                                  aria-selected="true"
                                >
                                  <img
                                    decoding="async"
                                    src="http://affiliate.universitybureau.com/content/uploads/2024/06/p1-il-2.webp"
                                    alt=""
                                  />
                                  Diploma
                                </button>
                              </li>
                            </ul>
                            {/* tabs-content */}
                            <div
                              className="tab-content kd-price-1-tabs-content"
                              id="myTabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="home0"
                                role="tabpanel"
                                aria-labelledby="home-tab0"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Minimum 60-65% in 12th grade <br />
                                      IELTS: Overall, 6 with no band less than 6
                                      <br />
                                      <br />
                                      Undergraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Arts
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Accounting/ Business/Administration
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Hospitality Management/ Hotel Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Journalism
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Engineering
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Information Technology/ Design
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Health
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home1"
                                role="tabpanel"
                                aria-labelledby="home-tab1"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Minimum 60% in bachelor's <br />
                                      IELTS: Overall, 6.5 with no band less than
                                      6
                                      <br />
                                      <br />
                                      Postgraduate Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Arts
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Accounting/ Business/Administration
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Information Technology/Artificial
                                        Intelligence
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business Management/Project Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Biotechnology/Nursing
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="home2"
                                role="tabpanel"
                                aria-labelledby="home-tab2"
                              >
                                <div className="kd-price-1-tabs-content-wrap">
                                  <div className="">
                                    <h4 className="title kd-heading-1 kd-font-900 has-clr-white">
                                      Minimum 60% in bachelor's <br />
                                      IELTS: Overall, 6.5 with no band less than
                                      6
                                      <br />
                                      <br />
                                      Diploma Streams
                                      <br />
                                      <br />
                                    </h4>
                                    <ul className="kd-list-1 kd-heading-1 kd-font-800 d-block">
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        Business/ Management
                                      </li>
                                      <li
                                        style={{
                                          padding: "10px 0",
                                          color: "#fff",
                                        }}
                                      >
                                        <i className="fa-solid fa-circle-check" />
                                        IT/Science
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-88d3249 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="88d3249"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a06ae77"
              data-id="a06ae77"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-aeadd90 elementor-widget elementor-widget-go-img--bx"
                  data-id="aeadd90"
                  data-element_type="widget"
                  data-widget_type="go-img--bx.default"
                >
                  <div className="elementor-widget-container">
                    <div className="kd-about-3-img-wrap txaa-slide-down-1">
                      <div className="kd-about-3-img img-cover fix kd-img-ani-1">
                        <img
                          decoding="async"
                          src="http://affiliate.universitybureau.com/content/uploads/2024/06/a3-img-1.webp"
                          alt=""
                        />
                      </div>
                      <div className="kd-about-3-img-2 img-cover fix txaa-slide-down-1-item">
                        <img
                          decoding="async"
                          src="http://affiliate.universitybureau.com/content/uploads/2024/06/a3-img-2.webp"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4203749"
              data-id={4203749}
              data-element_type="column"
              style={{
                flexDirection: "column",
                padding: "20px 70px",
                paddingRight: 0,
              }}
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-9ceb82e elementor-widget elementor-widget-go-s-heading"
                  data-id="9ceb82e"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Application Procedure in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-66aff77 elementor-widget elementor-widget-go-list-item"
                  data-id="66aff77"
                  data-element_type="widget"
                  data-widget_type="go-list-item.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-800 "
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          List of universities of your choice
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Requirements and documents
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Gather test scores of proficiency exams
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Finance your Studies
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Submit the Application
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Accept Your Offer
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Fee Deposit &amp; Accommodation
                        </span>
                      </li>
                      <li className="font-c-24">
                        <i className="fa-solid fa-circle-check" />
                        <span className="wow" data-splitting="">
                          Apply for a Visa
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-19ff2bc elementor-widget elementor-widget-ft-btn"
                  data-id="19ff2bc"
                  data-element_type="widget"
                  data-widget_type="ft-btn.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <a
                        href="#"
                        target="_blank"
                        rel="nofollow"
                        aria-label="name"
                        className="kd-pr-btn-1 text-uppercase has-pr-clr"
                      >
                        <span
                          className="text"
                          data-back="find out more"
                          data-front="Apply Now"
                        />
                        <span className="icon">
                          <i className="fa-solid fa-arrow-right" />
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="http://affiliate.universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        Requirement Documents
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Student Visa Requirements for United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <ul
                      className="kd-list-1 kd-heading-1 kd-font-500 "
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                      }}
                    >
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Letter of Acceptance:</b>
                          <br />
                          You must first obtain a letter of acceptance from a
                          designated learning institution (DLI) in United
                          Kingdom.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Valid Passport:</b>
                          <br />
                          Ensure your passport is valid for the duration of your
                          stay in United Kingdom
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Proof of Funds:</b>
                          <br />
                          You have enough to cover tuition fees, living
                          expenses, and return transportation.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Language Proficiency:</b>
                          <br />
                          Depending on the program and institution, you may need
                          to provide proof of proficiency in English or French.
                          Common language tests include IELTS or TOEFL for
                          English, and TEF for French.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Medical Examination:</b>
                          <br />A medical examination is required to prove that
                          you are in good health.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Police Certificate:</b>
                          <br />A police certificate may be required to
                          demonstrate that you have a clean criminal record.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Biometrics:</b>
                          <br />
                          It is required from most visa applicants. This
                          includes fingerprints and a photo.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>SOP:</b>
                          <br />
                          You will need to complete the appropriate application
                          form for a Uk's study permit.
                        </p>
                      </li>
                      <li className="font-c-20 custom-flex-box-1">
                        <i
                          className="fa-solid fa-circle-check font-c-40"
                          style={{ marginRight: 10 }}
                        />
                        <p className="m-0">
                          <b>Visa Processing Fee:</b>
                          <br />
                          Pay the required visa processing fee.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(http://affiliate.universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginTop: "2rem",
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="http://affiliate.universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="#" aria-label="name">
                                  Undergraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">
                                $14,000 – $36,123
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <div className="icon">
                                <img
                                  decoding="async"
                                  src="http://affiliate.universitybureau.com/content/uploads/2024/05/s1-icon-1.webp"
                                  alt="List"
                                />
                              </div>
                              <h4 className="title kd-heading-1 kd-font-900">
                                <a href="#" aria-label="name">
                                  Postgraduate
                                </a>
                              </h4>
                              <p className="disc kd-para-1">$6,210 – $43,990</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-d320ac4 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="d320ac4"
          data-element_type="section"
          style={{
            backgroundImage:
              "url(http://affiliate.universitybureau.com/content/uploads/2024/06/p1-bg-1.webp)",
            backgroundSize: "cover",
            marginBottom: 20,
            padding: "4rem 0",
          }}
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a3b221d"
              data-id="a3b221d"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <section
                  className="elementor-section elementor-inner-section elementor-element elementor-element-294c712 elementor-section-full_width txaa-slide-down-1 elementor-section-height-default elementor-section-height-default"
                  data-id="294c712"
                  data-element_type="section"
                >
                  <div className="elementor-container elementor-column-gap-no">
                    <div
                      className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-69d5dc8"
                      data-id="69d5dc8"
                      data-element_type="column"
                    >
                      <div className="elementor-widget-wrap elementor-element-populated">
                        <div
                          className="elementor-element elementor-element-9bd8b2b elementor-widget elementor-widget-go-info-box"
                          data-id="9bd8b2b"
                          data-element_type="widget"
                          data-widget_type="go-info-box.default"
                        >
                          <div className="elementor-widget-container">
                            <div className="kd-services-1-card  txaa-slide-down-1-item">
                              <table className="cost-table-design">
                                <tbody>
                                  <tr>
                                    <th>
                                      <b>Expense Category</b>
                                    </th>
                                    <th>
                                      <b>Estimated Monthly Cost (CAD)</b>
                                    </th>
                                  </tr>
                                  <tr>
                                    <td>Accommodation</td>
                                    <td>$800 - $2,500</td>
                                  </tr>
                                  <tr>
                                    <td>Food</td>
                                    <td>$300 - $500</td>
                                  </tr>
                                  <tr>
                                    <td>Transportation</td>
                                    <td> $80 - $150</td>
                                  </tr>
                                  <tr>
                                    <td>Health insurance</td>
                                    <td>$50 - $100</td>
                                  </tr>
                                  <tr>
                                    <td>Phone/Internet</td>
                                    <td>$100 - $200</td>
                                  </tr>
                                  <tr>
                                    <td>Miscellaneous</td>
                                    <td>Variable</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4af12a5"
              data-id="4af12a5"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-1649a90 elementor-widget elementor-widget-go-s-heading"
                  data-id="1649a90"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                  style={{ height: "100%" }}
                >
                  <div
                    className="elementor-widget-container"
                    style={{ height: "100%" }}
                  >
                    <div
                      className="prthalign custom-flex-box-2"
                      style={{ textAlign: "right", height: "100%" }}
                    >
                      <h6
                        style={{ color: "#fff" }}
                        className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub"
                      >
                        Study fees
                      </h6>
                      <h1
                        style={{ color: "#fff" }}
                        className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text"
                      >
                        Cost to <br />
                        Study in United Kingdom
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-e01eb3b elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="e01eb3b"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-default">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-fc8911c"
              data-id="fc8911c"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="elementor-element elementor-element-59eb154 elementor-widget elementor-widget-go-s-heading"
                  data-id="59eb154"
                  data-element_type="widget"
                  data-widget_type="go-s-heading.default"
                >
                  <div className="elementor-widget-container">
                    <div className="prthalign">
                      <h6 className="kd-subtitle-1 kd-subtitle-ani-1 elementor-kadu-sub">
                        <img
                          decoding="async"
                          src="http://affiliate.universitybureau.com/content/uploads/2024/05/star-1.webp"
                          alt=""
                        />
                        FAQs
                      </h6>
                      <h1 className="elementor-gt-heading kd-section-title-1 kd-title-ani kd-split-text">
                        Fequently Asked Questions
                      </h1>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-element elementor-element-7ec771b elementor-widget elementor-widget-go-ct-info"
                  data-id="7ec771b"
                  data-element_type="widget"
                  data-widget_type="go-ct-info.default"
                >
                  <div className="elementor-widget-container">
                    <div className="tutor-mt-40 kd-course-details-content">
                      <div className="cd-video-accordion">
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header is-active">
                            1. Why should I choose United Kingdom for my
                            studies?
                          </h4>
                          <div className="tutor-accordion-item-body" style={{}}>
                            <div className="item-body">
                              <p className="font-c-20">
                                United Kingdom provides outstanding
                                post-graduation possibilities, a safe
                                environment, different cultures, and
                                high-quality education. The nation is renowned
                                for its hospitable and inclusive communities,
                                and its universities have international
                                recognition.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                            2. What are the popular courses for international
                            students in United Kingdom?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                            style={{ display: "none" }}
                          >
                            <div className="item-body">
                              Courses in computer science, engineering, business
                              administration, environmental studies, and health
                              sciences are popular. The ideal path, however,
                              will rely on your professional objectives and
                              areas of interest.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                            3. How do I apply for a study permit in United
                            Kingdom?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                            style={{ display: "none" }}
                          >
                            <div className="item-body">
                              Visit the official Immigration, Refugees and
                              Citizenship United Kingdom (IRCC) website, and
                              submit an online application.
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                            4. Can I work while studying in United Kingdom?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                            style={{ display: "none" }}
                          >
                            <div className="item-body">
                              In general, foreign students are permitted to work
                              full-time during their designated breaks from
                              their studies and part-time during such breaks.
                              Study permits typically come with work permits
                            </div>
                          </div>
                        </div>
                        <div className="cd-video-accordion-item">
                          <h4 className="item-title tutor-accordion-item-header">
                            5. Are there scholarships available for
                            international students in United Kingdom?
                          </h4>
                          <div
                            className="tutor-accordion-item-body"
                            style={{ display: "none" }}
                          >
                            <div className="item-body">
                              Yes, several Uk's colleges and the federal
                              government provide scholarships to foreign
                              students based on leadership, academic
                              achievement, and other factors.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default UK;
